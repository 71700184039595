@import url('https://fonts.googleapis.com/css2?family=Montaga&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

$logofont: 'Montaga', serif;
$robotfont: 'Roboto', sans-serif;
$paperbg: #f7f7f7;

html, body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #eee;
  color: rgba(0, 0, 0, 0.87);
}

h1, h2, h3, h4, h5, h6 {
  margin-block-start: 0.25rem;
  margin-block-end: 0.25rem;
  color: rgba(0, 0, 0, 0.87);
}

a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);

  &:hover {
    color: #f68c1e;
  }
}